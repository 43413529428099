import React from "react";
import "../../css/landing-about.css";

const LandingAbout = () => {
  return (
    <div
      id="about"
      className="px-0 w-100 mx-auto py-5 ps-md-5 row align-items-center"
    >
      <div className="col-12 col-md-4 ps-0 banner-about__image">
        <img
          alt="home"
          src="/assets/banner-about.png"
          className="p-0 w-100 h-auto"
        />
      </div>

      <div className="col-12 col-md-8 mt-4">
        <div className="row text-center justify-content-center about__title">
          <p
            className="mb-0 text-dark title-font "
            style={{ fontWeight: 600, fontSize: "35px", width: "max-content" }}
          >
            ¿QUÉ ES BUNNATION?
          </p>

          <img src="/assets/logo.png" alt="logo" className="p-0 about__logo" />
        </div>

        <div className="row mt-4 px-3">
          <p className="about__description">
            Somos un estudio enfocado en el trabajo de lower body, combinando
            ejercicios de pesas, funcionales y cardio. Cada día de la semana se
            trabaja un músculo diferente, tenemos clases de Booty, Upper body,
            Butt & legs, Core, Full Body y clases de pilates.
          </p>

          <p className="title-font text-primary fs-1 text-center mt-4">
            queremos lograr
          </p>

          <p className="text-center mb-3">
            Crear una comunidad de personas fuertes, convirtiéndose en su mejor
            versión con un entrenamiento dinámico y efectivo.
          </p>

          <img
            src="/assets/three-lines.png"
            className="mx-auto mt-3 mb-5"
            style={{ width: "130px" }}
            alt="menu"
          />
        </div>
      </div>
    </div>
  );
};

export default LandingAbout;
