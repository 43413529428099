import React from "react";

const MapaLugares = ({ rows, place, setPlace, icon, taken_spots }) => {

  const renderRows = () => {
    let spotIndex = 1;
    
    return rows.map((cols, rowIndex) => {
      return (
        <div key={rowIndex} className="row my-3">
          {new Array(cols).fill(1).map((one, index) => {
            const currentSpot = `${spotIndex}`;
            spotIndex ++;

            return (
              <div key={index} className={`col mb-3 text-center`}>
                <div className="row justify-content-center">
                  <div
                    style={{ width: '40px', height: '40px', borderRadius: '50%', }}
                    className={`cursor-pointer fs-5 title-font p-0 d-flex justify-content-center align-items-center ${
                      Array.isArray(taken_spots) &&
                      Array.from(taken_spots).includes(
                        currentSpot
                      )
                        ? "bg-gray border border-2 border-accent text-accent "
                        : place === currentSpot
                        ? "bg-primary text-accent"
                        : "bg-accent text-gray"
                    }`}
                    onClick={() => setPlace(currentSpot)}
                    disabled={
                      Array.isArray(taken_spots) &&
                      Array.from(taken_spots).includes(
                        currentSpot
                      )
                    }
                  >
                    {icon && (
                      <i
                        className={`${icon} ${
                          Array.isArray(taken_spots) &&
                          Array.from(taken_spots).includes(
                            currentSpot
                          )
                            ? "text-dark"
                            : place === currentSpot
                            ? "text-white"
                            : "text-success"
                        }`}
                      />
                    )}
                    {parseInt(currentSpot) > 10 ? `c${parseInt(currentSpot) - 10}` : `f${currentSpot}`}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )
    })
  }

  return (
    <div id="mapa">
      {renderRows()}
    </div>
  );
};

export default MapaLugares;
