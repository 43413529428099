import React, { useContext, useEffect } from "react";
import PaqueteCard from "../paquetes/PaqueteCard";
import "../../css/landing-packages.css";
import { ClassPackagesContext } from "../../context/ClassPackagesContext";

const LandingPackages = () => {
  const { class_packages, getClassPackages } = useContext(ClassPackagesContext);

  useEffect(() => {
    getClassPackages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderClassPackages = () => {
    if (Array.isArray(class_packages))
      return class_packages.map((current) => {
        const headerTitle = current.header ? current.header : null;
        return (
          <div
            key={current.class_package_id}
            className="mb-4 container__card-package"
            style={{ width: "50%" }}
          >
            <PaqueteCard class_package={current} cardHeader={headerTitle} />
          </div>
        );
      });
  };
  return (
    <div id="paquetes" className="container-fluid pb-5 px-0 bg-accent">
      <div className="row mx-auto w-100">
        <div className="col-12 text-center">
          <h1
            className="text-dark text-center mt-5"
            style={{ fontSize: "40px" }}
          >
            PAQUETES
          </h1>
        </div>
      </div>

      <div
        className="row mx-auto w-100 justify-content-center"
        style={{ maxWidth: "1250px" }}
      >
        {renderClassPackages()}
      </div>
    </div>
  );
};
export default LandingPackages;
