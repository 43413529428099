import React, { useContext } from "react";
import { getValue } from "../../utils";
import "../../css/landing-video.css";
import { AppConfigContext } from "../../context/AppConfigContext";
import { Link } from "@reach/router";

const LandingVideo = () => {
  const appconfig = useContext(AppConfigContext);

  return (
    <div
      id="hero"
      className="row mx-0 align-items-center position-relative bg-gray"
    >
      <video
        loop
        muted
        autoPlay
        playsInline
        data-autoplay
        data-object-fit="cover"
        className="mw-100 w-100 home-video"
      >
        <source src="/hero-video.mp4" />
      </video>

      <div
        className="container text-center"
        style={{ zIndex: 3, maxWidth: "580px" }}
      >
        <h5 className="tagline text-primary mb-md-5 light">
          {getValue(appconfig, "hero_tagline")}
        </h5>
        <h1 className="display-1 text-shadow text-accent ">
          {getValue(appconfig, "hero_title")}
        </h1>

        <div className="row align-items-center">
          <div className="col-12 col-md-8">
            <Link
              to="/paquetes"
              className="btn btn-primary mt-3 mt-md-0 rounded-0 fs-3"
              style={{ width: "80%", fontWeight: 400 }}
            >
              Join Us
            </Link>
          </div>

          <div className="col-12 col-md-4 container__book-btn">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/bunnationstudio/"
              className="btn btn-accent mt-3 mt-md-0 my-md-auto rounded-0 border-0 book-btn"
            >
              Book a Visit
            </a>
          </div>
        </div>

        <div className="row position-absolute bottom-0 mb-3 justify-content-center w-100 show-mobile">
          <img
            src={"/assets/red-arrow-down.png"}
            alt="red arrow down"
            className=""
            style={{
              width: "auto",
              height: "60px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default LandingVideo;
