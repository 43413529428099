import React from "react";
import LandingClassTypes from "../components/landing/LandingClassTypes";

const ClassTypes = () => {

  return (
    <div className="container-fluid pt-3 px-0 bg-dark" >
      <div className="row justify-content-center">
        <h2 className="mt-3 text-accent fw-normal w-max-content" style={{ fontSize: "40px" }}>
          CLASSES
        </h2>
      </div>
    
      <div className="row mb-5">
        <div className="col-12">
          <p className="mt-3 mx-auto text-accent schedule__description text-center" >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </div>
      </div>

      <LandingClassTypes/>

    </div>
  );
};

export default ClassTypes;