import React from "react";
import LandingVideo from "../components/landing/LandingVideo";
import LandingAbout from "../components/landing/LandingAbout";
import LandingBanner from "../components/landing/LandingBanner";
import LandingCoaches from "../components/landing/LandingCoaches";
import LandingPackages from "../components/landing/LandingPackages";
import LandingSchedule from "../components/landing/LandingSchedule";
import LandingMap from "../components/landing/LandingMap";
import LandingClassTypes from "../components/landing/LandingClassTypes";

const Home = () => {
  return (
    <div className="container-fluid px-0" style={{ maxWidth: '100vw', overflow: 'hidden'}}>
      <LandingVideo/>
      <LandingAbout/>
      <LandingClassTypes />
      <LandingCoaches/>
      <LandingBanner/>
      <LandingPackages/>
      <LandingSchedule isHome={true} />
      <LandingMap/>
    </div>
  );
};

export default Home;
