import React, { useContext } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";
import { grayImg } from "../../utils";

const ClassTypeImage = ({ class_type }) => {
  const { S3_ENDPOINT } = useContext(AppConfigContext);

  const getSrc = () => {
    if (class_type.file && class_type.file !== null) {
      return `${S3_ENDPOINT}/${class_type.file.name}.${class_type.file.type}`;
    } else {
      return grayImg;
    }
  };

  const renderMedia = () => {
    if (class_type.video && class_type.video !== null) {
      return (
        <video className="class-type-home bg-gray px-0" playsInline autoPlay muted>
          <source src={class_type.video.src} type="video/mp4" />
        </video>
      );
    }
    return (
      <img
        src={getSrc()}
        className="class-type-home bg-gray px-0"
        alt={class_type.name}
      />
    );
  };

  return (
    <div className="row px-0 class-type__image">
      {renderMedia()}

      <div
        style={{ width: "max-content" }}
        className="p-3 class-type-title position-absolute mx-auto end-0 start-0 bottom-0"
      >
        <p
          style={{ fontWeight: 600, fontSize: "20px", width: "max-content" }}
          className="mb-0 font-title text-accent"
        >
          {class_type.name}
        </p>
      </div>
    </div>
  );
};

export default ClassTypeImage;
