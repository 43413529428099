export const isFull = (single_class) => {
  if (single_class !== null) {
    if (
      single_class.capacity !== null &&
      Array.isArray(single_class.class_reservations)
    ) {
      return (
        single_class.capacity - single_class.class_reservations.length <= 0
      );
    }
  }
};
