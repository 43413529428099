import React from "react";
import MisReservaciones from "./MisReservaciones";
import MiInformacion from "./MiInformacion";
import { Router } from "@reach/router";
import MisMetodos from "./MisMetodos";
import Purchases from "./Purchases";
import Invoices from "./Invoices";
import Shop from "./Shop";
import LandingSchedule from "../components/landing/LandingSchedule";
import Leaderboard from "./Leaderboards";


const Panel = () => {
  return (
    <div className="container-fluid px-0">
      <div className="main-panel">
        <Router>
          <Shop path="/paquetes" />
          <LandingSchedule path="/schedule" default />
          <MiInformacion path="/info" />
          <MisMetodos path="/methods" />
          <MisReservaciones path="/reservations" />
          <Purchases path="/memberships" />
          <Invoices path="/payments" />
          <Leaderboard path="/leaderboard" />
        </Router>
      </div>
    </div>
  );
};

export default Panel;
