import React, { useContext } from "react";
import { Link } from "@reach/router";
import { HomeContext } from "../../context/HomeContext";
import { AuthContext } from "../../context/AuthContext";

const MobileMenu = ({ signOut }) => {
  const { user } = useContext(AuthContext);
  const { currentTab, setCurrentTab} = useContext(HomeContext);

  const menuItems = [
    { label: 'HOME', to: '/' },
    { label: 'CLASSES', to: '/classes' },
    { label: 'SCHEDULE', to: '/schedule' },
    { label: 'COACHES', to: '/coaches' },
    { label: 'PAQUETES', to: '/paquetes' },
  ];

  const renderMenuItems = () => {
    if(user !== null) {
      menuItems.unshift({ label: 'PERFIL', to: '/mybunnation/info' },)
    } else {
      menuItems.unshift({ label: 'LOGIN', to: '/login' });
    }
    return menuItems.map(item => {
      const isActive = item.to === currentTab.to;

      if(isActive) {
        return (
          <li key={item.to} className="nav-item">
            <Link 
              className="nav-link fs-2 text-primary" 
              to={item.to}
              onClick={() => setCurrentTab(item)}
            >
              {item.label}
            </Link>
          </li>
        )
      } else {
        return (
          <li key={item.to} className="nav-item">
            <Link 
              className="nav-link fs-2 text-gray"
              to={item.to}
              onClick={() => setCurrentTab(item)}
            >
              {item.label}
            </Link>
          </li>
        )
      }
    })
  }

  return (
    <div
      className="offcanvas offcanvas-end"
      tabIndex="-1"
      id="offcanvasNavbar"
      aria-labelledby="offcanvasNavbarLabel"
    >
      <div className="offcanvas-header my-0 ps-0 pb-0">
        <button
          type="button"
          className="btn text-primary mx-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <i className="fas fa-chevron-right fs-2"/>
        </button>
      </div>

      <div className="offcanvas-body pt-0 px-4">
        <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
          {renderMenuItems()}
        </ul>
        
      </div>
    </div>
  );
};

export default MobileMenu;
