import React, { useContext, useEffect } from "react";
import { WaitlistsContext } from "../context/WaitlistsContext";
import WaitlistRow from "../components/waitlist/WaitlistRow";
import { ModalContext } from "../context/ModalContext";
import Waitlist from "../components/waitlist/Waitlist";
import { Link } from "@reach/router";
import moment from "moment";

const Waitlists = ({ filter }) => {
  const { waitlists, getWaitlists } = useContext(WaitlistsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getWaitlists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmCancel = (waitlist) => {
    modalComponent(
      "Lista de Espera",
      <Waitlist
        single_class_id={waitlist.single_class_id}
        handleCancel={clearModal}
      />
    );
  };

  const displayInfo = () => {
    modalComponent(
      "Lista de Espera",
      <div>
        <h4>¿Cómo funciona la lista de espera?</h4>
        <p>
          Si algún cliente cancela, recibirás un mensaje por WhatsApp para tomar
          su lugar.
        </p>
        <p>
          Todos los clientes en lista de espera reciben el mensaje al mismo
          tiempo, el primero en reservar se quedará con el lugar.
        </p>
        <button onClick={clearModal} className="btn btn-primary w-100">
          OK
        </button>
      </div>
    );
  };

  const renderWaitlists = () => {
    if (Array.isArray(waitlists)) {
      let waitlistsRender = [...waitlists];
      if (filter === "past") {
        waitlistsRender = waitlistsRender.filter((waitlist) =>
          moment(waitlist.single_class.class_date)
            .utc()
            .isBefore(moment().utc(true))
        );
      } else if (filter === "next") {
        waitlistsRender = waitlistsRender.filter((waitlist) =>
          moment(waitlist.single_class.class_date)
            .utc()
            .isAfter(moment().utc(true))
        );
      }
      if (waitlistsRender.length === 0) {
        return (
          <div className="container-fluid p-3">
            <p>No estás anotado en ninguna lista de espera.</p>
            <Link to="/mybunnation/presencial" className="btn btn-primary">
              Ir a Reservar
            </Link>
            <button onClick={displayInfo} className="btn ms-2 px-3 btn-outline-primary">
              <i className="fa fa-info" />
            </button>
          </div>
        );
      }
      return waitlistsRender.map((waitlist) => (
        <WaitlistRow
          key={waitlist.class_waitlist_id}
          confirmCancel={confirmCancel}
          waitlist={waitlist}
          lightCard
        />
      ));
    }
    return <div className="spinner-border"></div>;
  };

  return <div className="row">{renderWaitlists()}</div>;
};

export default Waitlists;
