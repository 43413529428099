export const handleColors = (config) => {
  const props = ["primary", "accent", "dark", "gray", "light", "primary-light"];
  props.forEach((key) => {
    const color = config[key];
    document.documentElement.style.setProperty(`--${key}`, color);
  });
};

export const handleMetaTags = (config) => {
  const {
    favicon,
    social_media_image,
    business_name,
    tagline,
    head_tracking_codes,
    body_tracking_codes,
    S3_ENDPOINT,
  } = config;

  var link = document.querySelector("link[rel~='icon']");
  if (link) {
    link.href = `${S3_ENDPOINT}/${favicon}`;
  }

  document.title = `${business_name}`;

  if (tagline) {
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", tagline);
  }

  if (social_media_image) {
    document
      .querySelector('meta[name="og:image"]')
      .setAttribute("content", social_media_image);
  }

  if (head_tracking_codes) {
    const script = document.querySelector("script#head_tacking_codes");
    script.innerHTML = `
        try {
        
          ${head_tracking_codes}
        } catch (error) {
          
        }
      `;
  }

  if (body_tracking_codes) {
    const script = document.querySelector("script#body_tacking_codes");
    script.innerHTML = `
        try {
        
          ${body_tracking_codes}
        } catch (error) {
          
        }
    `;
  }
};
