import React from "react";
import { formatMonto } from "../../utils";
import { Link } from "@reach/router";

const PaqueteCard = ({ class_package }) => {
  const renderCardHeader = () => {
    if (class_package.short_description && class_package.short_description !== null) {
      return (
        <div className="card__header row bg-dark w-100 mx-auto">
          <p
            className="mb-0 py-1 text-center text-accent"
          >
            {class_package.short_description}
          </p>
        </div>
      );
    }
  };

  const renderExpirationDays = () => {
    if (class_package.expiration_days) {
      return (
        <p style={{ fontSize: "10px" }} className="text-dark text-center package__expiration">
          Vigencia por {class_package.expiration_days} días
        </p>
      );
    }
  };

  const renderDescription = () => {
    const { description} = class_package;
    if (description && description !== null) {
      return <p style={{ fontSize: "10px" }} className="text-dark text-center">{description}</p>
    }
  }

  return (
    <div
      className="card__package card justify-content-between w-100 h-100 border bg-accent p-0 border border-dark rounded-0"
      style={{ height: "107px" }}
    >
      {renderCardHeader()}

      <div className="row mx-auto h-100 align-items-center w-100">
        <h2
          className="package__title text-dark mb-0 fw-normal text-center mt-2"
        >
          {class_package.title}
        </h2>
        <h6
          className="package__price text-primary mb-2 text-center px-2 fw-normal fs-5"
        >
          ${formatMonto(class_package.price)}
        </h6>
        {renderExpirationDays()}
        {renderDescription()}
      </div>

      <Link
        type="button"
        to={`/checkout/${class_package.class_package_id}`}
        className="package__btn btn btn-primary w-100 rounded-0 fw-normal"
      >
        Comprar Paquete
        <i className="fas fa-chevron-right ps-2" />
      </Link>
    </div>
  );
};

export default PaqueteCard;
