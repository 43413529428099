import React, { createContext, useReducer } from "react";
import { CLASS_TYPES_RECEIVED, SET_CLASS_TYPE } from "../actions/class_types";
import ClassTypesReducer from "../reducers/ClassTypesReducer";
import ClassTypesService from "../services/ClassTypesService";

const initialState = {
  class_types: null,
  class_type: null,
};

export const ClassTypeContext = createContext(initialState);

export const ClassTypeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ClassTypesReducer, initialState);

  const getClassTypes = () => {
    ClassTypesService.getClassTypes().then((res) => {
      const { class_types } = res.data;
      dispatch({ type: CLASS_TYPES_RECEIVED, payload: class_types });
    });
  };

  const getHomeClassTypes = () => {
    ClassTypesService.getHomeClassTypes().then((res) => {
      const { class_types } = res.data;
      dispatch({ type: CLASS_TYPES_RECEIVED, payload: class_types });
    });
  };

  const getClassType = (class_type_id) => {
    ClassTypesService.getClassTypes().then((res) => {
      const { class_types } = res.data;
      let class_type = class_types.find(
        (tipo) => parseInt(tipo.class_type_id) === parseInt(class_type_id)
      );
      dispatch({ type: SET_CLASS_TYPE, payload: class_type });
    });
  };

  return (
    <ClassTypeContext.Provider
      value={{
        ...state,
        getClassType,
        getClassTypes,
        getHomeClassTypes
      }}
    >
      {children}
    </ClassTypeContext.Provider>
  );
};
