import React, { useState, useEffect } from "react";
import ImageInputThumbnail from "./ImageInputThumbnail";
import { grayImg } from "../../utils";

const ImageInput = ({
  value,
  base_url,
  modifier,
  multiple,
  hideImage,
  hideLabel,
}) => {
  const [src, setSrc] = useState("");
  const [files, setFiles] = useState(null);
  const [srcSet, setSrcSet] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (value && files === null) {
      setSrc(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (files?.length > 0) {
      if (multiple) {
        const fileSet = [];
        const promises = [];
        for (let i = 0; i < files.length; i++) {
          const file = files[i];

          let reader = new FileReader();

          const currentPromise = new Promise((resolve, reject) => {
            reader.readAsDataURL(file);

            reader.onload = (e) => {
              let src = e.target.result;
              fileSet.push(src);
              resolve();
            };
          });

          promises.push(currentPromise);
        }

        Promise.all(promises).then(() => {
          setSrcSet(fileSet);
          setLoading(false);
        });
      } else {
        const file = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          setSrc(e.target.result);
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const renderImages = () => {
    if (multiple && Array.isArray(value)) {
      return value.map((src, index) => (
        <ImageInputThumbnail key={index} src={src} />
      ));
    }
    if (multiple && srcSet.length > 0) {
      return srcSet.map((src, index) => (
        <ImageInputThumbnail key={index} src={src} />
      ));
    }
  };

  const handleModifier = (currentFiles) => {
    setFiles(currentFiles);
    if (multiple) {
      modifier(currentFiles);
      setLoading(true);
    } else {
      modifier(currentFiles[0]);
    }
  };

  const selectFile = () => {
    document.getElementById("fileInput").click();
  };

  const imageInputHandler = (e) => {
    if (e.target.files !== undefined) {
      handleModifier(e.target.files);
    }
  };

  const renderButtonContent = () => {
    if (loading) {
      return <div className="spinner-border" />;
    }
    if (!hideLabel) {
      return (
        <span className="small ms-2 d-inline-block">
          {src !== "" ? "Reemplazar" : "Subir"}
        </span>
      );
    }
  };

  const getImageSrc = () => {
    if(src) {
      return src;
    } else {
      return grayImg;
    }
  }

  return (
    <div className="container-fluid px-0" style={{ position: "relative" }}>
      <div className="row align-items-center justify-content-start">
        
        {!hideImage && (
          <div className="col-12 text-center mb-3">
            <img
              src={getImageSrc()}
              alt="user profile"
              className="d-block bg-gray mx-auto"
              style={{ width: '170px', height: '230px', objectFit: "cover" }}
            />
          </div>
        )}

        <div className={`col-12`}>
          <input
            className="d-none"
            style={{ cursor: "pointer" }}
            id="fileInput"
            type="file"
            accept="image/*"
            multiple={multiple}
            onChange={imageInputHandler}
          />
          <button
            type="button"
            disabled={loading}
            onClick={selectFile}
            className="btn px-1 w-100 btn-outline-primary"
          >
            <i className="fas fa-image"></i> {renderButtonContent()}
          </button>
        </div>

        <div className="container-fluid">{renderImages()}</div>
      </div>
    </div>
  );
};

export default ImageInput;