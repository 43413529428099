import React, { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { HomeContext } from "../../context/HomeContext";

const ProfileMobile = ({
  sectionButtons,
  currentSection,
  setCurrentSection,
}) => {
  const [menuOpened, setMenuOpened] = useState(true);
  const { signOut } = useContext(AuthContext);
  const { setCurrentTab } = useContext(HomeContext);

  const renderCurrentSection = () => {
    return (
      <button
        type="button"
        className={`fs-5 text-primary fw-light btn text-start position-relative bg-accent`}
        style={{ width: "180px" }}
      >
        {currentSection.label}
      </button>
    );
  };

  const renderMenuLeftBtns = () => {
    const leftBtnsIndex = [0, 1];
    return leftBtnsIndex.map((index) => {
      const menuBtn = sectionButtons[index];
      return (
        <div key={index} className="row">
          <button
            type="button"
            className={` description-font fw-light w-max-content btn text-dark text-start position-relative bg-accent`}
            onClick={() => setCurrentSection(menuBtn)}
            style={{ width: "180px", fontSize: "14px" }}
          >
            {menuBtn.label}
          </button>
        </div>
      );
    });
  };

  const renderMenuRightBtns = () => {
    const rightBtnsIndex = [2, 3];
    return rightBtnsIndex.map((index) => {
      const menuBtn = sectionButtons[index];
      return (
        <div key={index} className="row">
          <button
            type="button"
            className={` description-font fw-light w-max-content btn text-dark text-start position-relative bg-accent`}
            onClick={() => setCurrentSection(menuBtn)}
            style={{ width: "180px", fontSize: "14px" }}
          >
            {menuBtn.label}
          </button>
        </div>
      );
    });
  };

  return (
    <div className="row flex-column mt-5 show-mobile ">
      <div
        className="container profile__mobile-menu px-0 m-0 py-0 w-100 border-top border-bottom border-2 border-dark"
        style={{ width: "200px" }}
      >
        <div className="row flex-column">
          <div className="col-12">
            <div className="row justify-content-between">
              {renderCurrentSection()}

              <button
                type="button"
                className={`fs-5 fw-normal w-max-content btn text-dark text-start position-relative bg-accent`}
                onClick={() => {
                  setMenuOpened(!menuOpened);
                }}
                style={{ width: "180px" }}
              >
                Opciones
                <i
                  className={`fas ${
                    menuOpened ? "fa-chevron-up" : "fa-chevron-down"
                  } ms-3 text-primary`}
                />
              </button>
            </div>

            <div className={`row ${menuOpened ? "" : "d-none"}`}>
              <div className="col-6 border-end border-dark">
                {renderMenuLeftBtns()}
              </div>

              <div className="col-6">
                {renderMenuRightBtns()}

                <div className="row">
                  <button
                    type="button"
                    className={`description-font w-max-content fw-light btn text-dark text-start position-relative bg-accent`}
                    onClick={() => {
                      signOut();
                      setCurrentTab({ label: "Login", to: "/login" });
                    }}
                    style={{ width: "180px", fontSize: "14px" }}
                  >
                    Log Out
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container px-0" style={{ flex: 1, minHeight: "500px" }}>
        {currentSection.component}
      </div>
    </div>
  );
};

export default ProfileMobile;
