import React from "react";
import { navigate } from "@reach/router";
import ClassTypeImage from "./ClassTypeImage";
import ClassTypeContent from "./ClassTypeContent";

const HomeClassTypeCard = ({ class_type }) => {
  return (
    <div
      className="row mx-auto w-100 cursor-pointer px-0"
      onClick={() => navigate(`/class_type/${class_type.class_type_id}`)}
    >
      <div className=" col-12 px-0 shadow-sm position-relative">
        <ClassTypeImage class_type={class_type} />
      </div>

      <div className="col-12 bg-dark">
        <ClassTypeContent class_type={class_type} />
      </div>
    </div>
  );
};

export default HomeClassTypeCard;
