import React, { useContext, useEffect } from "react";
import Banner from "../components/global/Banner";
import CoachCard from "../components/coaches/CoachCard";
import { InstructorsContext } from "../context/InstructorsContext";

const Coaches = () => {

  const { instructors, getInstructors } = useContext(InstructorsContext);

  useEffect(() => {
    getInstructors();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCoaches = () => {
    if (Array.isArray(instructors)) {
      return instructors.map((current) => {
        return (
          <div key={current.instructor_id} className="col-6 mb-5" style={{minWidth: '360px'}}>
            <div className="row" style={{ width: "350px", height: "500px" }}>
              <CoachCard coach={current} />
            </div>
          </div>
        );
      });
    }
  };

  return (
    <div className="container-fluid pt-3 px-0 bg-accent">
      <div className="coaches__header row w-100 mx-auto mb-5 justify-content-center px-3">
        <p
          className=" px-3 title-font text-center text-dark"
          style={{ fontSize: "40px", fontWeight: 600, width: "max-content" }}
        >
          MEET YOUR COACHES
        </p>
      </div>

      <div className="row justify-content-center" style={{ maxWidth: "800px" }}>
        {renderCoaches()}
      </div>

      <Banner />
    </div>
  );
};

export default Coaches;
