import React, { useEffect, useState } from "react";
import ClassTypeImage from "./ClassTypeImage";
import ClassTypeContent from "./ClassTypeContent";
import "../../css/class-types-collage.css";

const ImageRightContainer = ({ children }) => {
  return (
    <div className="row class-types__img-right px-0 position-relative">
      {children}
    </div>
  );
};

const ImageLeftContainer = ({ children }) => {
  return (
    <div className="row class-types__img-left px-0 position-relative">
      {children}
    </div>
  );
};

const ContentRightContainer = ({ children }) => {
  return (
    <div className="row class-types__content-right px-0 position-relative">
      {children}
    </div>
  );
};

const ContentLeftContainer = ({ children }) => {
  return (
    <div className="row class-types__content-left px-0 position-relative">
      {children}
    </div>
  );
};

const ClassTypesCollage = ({ class_types }) => {
  const [leftComponents, setLeftComponents] = useState([]);
  const [rightComponents, setRightComponents] = useState([]);

  useEffect(() => {
    handleClassTypesOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [class_types]);

  const handleClassTypesOrder = () => {
    const leftCards = [];
    const rightCards = [];

    if (Array.isArray(class_types)) {
      class_types?.forEach((current, index) => {
        const currentContentRightCard = () => (
          <ContentRightContainer key={current.class_type_id}>
            <ClassTypeContent class_type={current} />
          </ContentRightContainer>
        );

        const currentContentLeftCard = () => (
          <ContentLeftContainer key={current.class_type_id}>
            <ClassTypeContent class_type={current} />
          </ContentLeftContainer>
        );

        const currentImageRightCard = () => (
          <ImageRightContainer key={current.class_type_id}>
            <ClassTypeImage class_type={current} />
          </ImageRightContainer>
        );

        const currentImageLeftCard = () => (
          <ImageLeftContainer key={current.class_type_id}>
            <ClassTypeImage class_type={current} />
          </ImageLeftContainer>
        );

        if (index === 0 || index === 2) {
          leftCards.push(currentContentLeftCard);
          rightCards.push(currentImageRightCard);
        } else {
          leftCards.push(currentImageLeftCard);
          rightCards.push(currentContentRightCard);
        }
      });

      setLeftComponents(leftCards);
      setRightComponents(rightCards);
    }
  };

  const renderLeftCards = () => {
    if (leftComponents?.length > 0) {
      return leftComponents.map((component) => component());
    }
  };

  const renderRightCards = () => {
    if (rightComponents?.length > 0) {
      return rightComponents.map((component) => component());
    }
  };

  return (
    <div className="container-fluid p-0 mt-5 bg-white">
      <div className="row">
        <div className="col-6 px-0">{renderLeftCards()}</div>

        <div className="col-6 px-0">{renderRightCards()}</div>
      </div>
    </div>
  );
};

export default ClassTypesCollage;
