import React from "react";
import { useContext } from "react";
import { HomeContext } from "../../context/HomeContext";
import "../../css/navbar.css";
import { Link } from "@reach/router";
import { navigate } from "@reach/router";
import { AuthContext } from "../../context/AuthContext";
import UserNavBtn from "./UserNavBtn";
import { AppConfigContext } from "../../context/AppConfigContext";

const Navbar = () => {
  const { currentTab, setCurrentTab, menuItems } = useContext(HomeContext);
  const { cancel_timeframe_value } = useContext(AppConfigContext);
  const { user } = useContext(AuthContext);

  const renderTabs = () => {
    return menuItems.map((item) => {
      if (item.hidden) return;
      const isActive = item.to === currentTab.to;

      if (isActive) {
        return (
          <Link
            key={item.to}
            className="nav-link mt-0 fs-4 text-primary title-font"
            to={item.to}
            onClick={() => setCurrentTab(item)}
            style={{ width: "max-content" }}
          >
            {item.label}
          </Link>
        );
      } else {
        return (
          <Link
            key={item.to}
            className="nav-link mt-0 fs-4 text-accent title-font"
            to={item.to}
            onClick={() => setCurrentTab(item)}
            style={{ width: "max-content" }}
          >
            {item.label}
          </Link>
        );
      }
    });
  };

  const renderDesktopMenuItems = () => {
    return (
      <div
        className="row w-100 h-100 mx-auto justify-content-between align-items-center hide-mobile"
        style={{ maxWidth: "600px" }}
      >
        {renderTabs()}
      </div>
    );
  };

  const renderProfileBtn = () => {
    if (user) {
      return <UserNavBtn hideMobile />;
    } else {
      return (
        <button
          className={`btn bg-dark hide-mobile fs-4 fw-normal h-max-content w-max-content ${
            currentTab.to.includes("/login") ? "text-primary" : "text-accent"
          }`}
          type="button"
          onClick={() => {
            setCurrentTab({ label: "Login", to: "/login" });
            navigate("/login");
          }}
        >
          LOGIN
        </button>
      );
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-dark fixed-top mw-100 shadow-sm">
      <div className="container-fluid navbar-container px-0 h-100">
        <div className="row w-100 h-100 mx-auto">
          <div className="col-12 text-center text-accent border-bottom border-primary h-max-content">
            <p className="mt-3 mb-0 nav-advise">
              Cancelaciones o cambios son {cancel_timeframe_value} horas antes
              de tu clase
            </p>
          </div>

          <div className="col-12 mt-2 navbar__actions">
            <div
              className="row w-100 h-100 mx-auto"
              style={{ maxWidth: "1320px" }}
            >
              <div className="col-3">
                <div className="row w-100 h-100 mx-auto justify-content-center align-items-center">
                  <a className="navbar-brand p-2 show-mobile" href="/#landing">
                    <img
                      src={"/assets/logo.png"}
                      alt="logo"
                      className="logo-navbar"
                    />
                  </a>
                  <a className="navbar-brand p-2 hide-mobile" href="/#landing">
                    <img
                      src={"/assets/full-logo.png"}
                      alt="logo"
                      style={{ width: "150px", height: "auto" }}
                    />
                  </a>
                </div>
              </div>

              <div className="col-6 px-0">
                <div className="row w-100 h-100 mx-auto justify-content-center align-items-center show-mobile-flex">
                  <p
                    style={{ fontWeight: 400 }}
                    className="title-font fs-3 text-center text-primary mb-0"
                  >
                    {currentTab.label}
                  </p>
                </div>
                {renderDesktopMenuItems()}
              </div>

              <div className="col-3 text-end pe-0">
                <div className="row align-items-center justify-content-end h-100">
                  {renderProfileBtn()}
                  <button
                    className="navbar-toggler h-max-content w-max-content show-mobile"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasNavbar"
                    aria-controls="offcanvasNavbar"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <i className="fas fa-bars text-accent fs-2"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
