import React from "react";

const LandingMap = () => {

  return (
    <div className="row mx-0 align-items-center position-relative">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3596.3671745136385!2d-100.37798022406032!3d25.65912047741644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662bd58defcd0b7%3A0x53126cfe560a9268!2sBunnation%20Studio!5e0!3m2!1ses-419!2smx!4v1721087377225!5m2!1ses-419!2smx"
        style={{ border: 0, height: '700px' }}
        allowFullScreen
        loading="lazy"
        title="map"
        referrerPolicy="no-referrer-when-downgrade"
        className="p-0 w-100"
      ></iframe>
    </div>
  );
};

export default LandingMap;
