import React, { createContext, useContext, useReducer } from "react";
import PaquetesService from "../services/PaquetesService";
import { PRESENCIALES_HOME_RECIBIDOS, SET_CURRENT_TAB } from "../actions";
import HomeReducer from "../reducers/HomeReducer";
import { ModalContext } from "./ModalContext";

const initialState = {
  presenciales: null,
  coaches: null,
  currentTab: { 
    label: '', 
    to: '' 
  },
  menuItems: [
    { label: 'Home', to: '/' },
    { label: 'Classes', to: '/classes' },
    { label: 'Schedule', to: '/schedule' },
    { label: 'Coaches', to: '/coaches' },
    { label: 'Paquetes', to: '/paquetes' },
    { label: 'Login', to: '/login', hidden: true },
  ]
};

export const HomeContext = createContext(initialState);

export const HomeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(HomeReducer, initialState);

  const { success, clearModal } = useContext(ModalContext);

  const getPresenciales = () => {
    PaquetesService.getPresencialesHome().then((res) => {
      const { paquetes } = res.data;
      dispatch({ type: PRESENCIALES_HOME_RECIBIDOS, payload: paquetes });
    });
  };

  const setCurrentTab = (payload) => {
    dispatch({ type: SET_CURRENT_TAB, payload });
  };

  const addToHome = (package_class_id, type) => {
    PaquetesService.addToHome(package_class_id, type).then(() => {
      success("¡Paquete agregado al home con éxito!");
      getPresenciales();
      clearModal();
    });
  };

  const deleteFromHome = (package_class_id, type) => {
    PaquetesService.deleteFromHome(package_class_id, type).then(() => {
      success("¡Paquete eliminado del home!");
      getPresenciales();
      clearModal();
    });
  };

  return (
    <HomeContext.Provider
      value={{
        ...state,
        getPresenciales,
        deleteFromHome,
        addToHome,
        setCurrentTab
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};
