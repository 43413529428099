import React, { useContext, useEffect } from "react";
import PaqueteCard from "../components/paquetes/PaqueteCard";
import { ClassPackagesContext } from "../context/ClassPackagesContext";
import { navigate } from "@reach/router";

const Shop = () => {
  const { class_packages, getClassPackages } = useContext(ClassPackagesContext);

  useEffect(() => {
    getClassPackages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderSingleClass = () => {
    if (Array.isArray(class_packages)) {
      let paquetesRender = class_packages.filter(
        ({ class_amount, limit_per_customer }) => class_amount === 1 && limit_per_customer === 1
      );
      if (paquetesRender.length === 0) {
        return <p>No hay paquetes disponibles.</p>;
      }
      return paquetesRender.map((paquete) => (
        <PaqueteCard
          key={paquete.class_package_id}
          class_package={paquete}
          className="btn btn-primary bold btn-block w-100"
          action={() => {
            navigate(`/checkout/${paquete.class_package_id}`);
          }}
        />
      ));
    }
    return <div className="spinner-border mx-2" role="status"></div>;
  };

  const renderPresenciales = () => {
    if (Array.isArray(class_packages)) {
      let paquetesRender = class_packages.filter(
        (paquete) => !paquete.is_special_event && paquete.class_amount > 0 && paquete.limit_per_customer !== 1
      );
      if (paquetesRender.length === 0) {
        return <p>No hay paquetes disponibles.</p>;
      }
      return paquetesRender.map((paquete) => (
        <PaqueteCard
          key={paquete.class_package_id}
          paquete={paquete}
          className="btn btn-primary bold btn-block w-100"
          action={() => {
            navigate(`/checkout/${paquete.class_package_id}`);
          }}
        />
      ));
    }
    return <div className="spinner-border mx-2" role="status"></div>;
  };

  return (
    <div className="container-fluid py-3">
      <h2 className="pb-3 border-bottom">Clase de Prueba</h2>
      <div className="row align-items-center">
        {renderSingleClass()}
      </div>
      <h2 className="pb-3 border-bottom mt-5">
        Paquetes
      </h2>
      <div className="row">{renderPresenciales()}</div>
    </div>
  );
};

export default Shop;
