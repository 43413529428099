import React from "react";

const ColorLegend = () => {
  return (
    <div className="container-fluid px-0 mb-2">
      <div className="row">
        <div className="col-4">
          <div className="row">
            <div className="col-4 col-md-2 px-0">
              <div className="color-legend br-25 bg-accent pe-0"></div>
            </div>
            <div className="col-8 col-md-10 ps-0">
              <span className="small text-white" style={{ fontSize: 12 }}>Disponible</span>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="row">
            <div className="col-4 col-md-2 px-0">
              <div className="color-legend br-25 bg-primary pe-0"></div>
            </div>
            <div className="col-8 col-md-10 ps-0">
              <span className="small text-white" style={{ fontSize: 12 }}>Seleccionada</span>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="row">
            <div className="col-4 col-md-2 px-0">
              <div className="color-legend br-25 bg-gray pe-0"></div>
            </div>
            <div className="col-8 col-md-10 ps-0">
              <span className="small text-white" style={{ fontSize: 12 }}>Ocupado</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ColorLegend;
