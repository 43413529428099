import api from "./api";
import { getArgs } from "../utils";

const route = "/analytics";

const AnaliticasService = {
  getLeaderboard: (filters) =>
    api.get(`${route}/leaderboard?${getArgs(filters)}`),
};

export default AnaliticasService;
