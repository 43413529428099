import React, { useContext, useEffect } from "react";
import LeaderboardTable from "../components/leaderboard/LeaderboardTable";
import { AnaliticasContext } from "../context/AnaliticasContext";
import moment from "moment";

const Leaderboard = () => {
  const start_date = moment().startOf("month").format("YYYY-MM-DD");
  const end_date = moment().endOf("month").format("YYYY-MM-DD");

  const { leaderboard, getLeaderboard } = useContext(AnaliticasContext);

  useEffect(() => {
    getLeaderboard({ start_date, end_date });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-fluid px-4 px-md-5 py-3">
      <div className="row my-3 align-items-center">
        <div className="col-10">
          <h1 className="h2 bold mb-0">Leaderboard</h1>
        </div>
        <div className="col-2 text-end">
          <h2 className="h3 mb-0">{moment().format("MMM")}</h2>
        </div>
      </div>
      <div className="card p-3 no-scale shadow">
        <LeaderboardTable customers={leaderboard} />
      </div>
    </div>
  );
};

export default Leaderboard;
