import { PRESENCIALES_HOME_RECIBIDOS, SET_CURRENT_TAB } from "../actions";

const HomeReducer = (state, { type, payload }) => {
  switch (type) {
    case PRESENCIALES_HOME_RECIBIDOS:
      return { ...state, presenciales: payload };
    case SET_CURRENT_TAB:
      return { ...state, currentTab: payload };
    default:
      return { ...state };
  }
};

export default HomeReducer;
