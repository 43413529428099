import React, { createContext, useReducer } from "react";
import AnaliticasReducer from "../reducers/AnaliticasReducer";
import AnaliticasService from "../services/AnaliticasService";
import { LEADERBORARD_RECEIVED } from "../actions/analytics";

const initialState = {
  alumnas: null,
  paquetes: null,
  ingresos: null,
};

export const AnaliticasContext = createContext(initialState);

export const AnaliticasProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AnaliticasReducer, initialState);

  const getLeaderboard = (filters) => {
    AnaliticasService.getLeaderboard(filters).then((res) => {
      const { leaderboard } = res.data;
      dispatch({ type: LEADERBORARD_RECEIVED, payload: leaderboard });
    });
  };

  const clearLeaderboard = () => {
    dispatch({ type: LEADERBORARD_RECEIVED, payload: null });
  };

  return (
    <AnaliticasContext.Provider
      value={{
        ...state,
        getLeaderboard,
        clearLeaderboard,
      }}
    >
      {children}
    </AnaliticasContext.Provider>
  );
};
