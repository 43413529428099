import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { getUserSrc } from "../../utils";
import { AvailableClassesContext } from "../../context/AvailableClassesContext";

const ProfileData = () => {
  const { user } = useContext(AuthContext);
  const { available_classes, getAvailableClasses } = useContext(
    AvailableClassesContext
  );

  useEffect(() => {
    if (user !== null) getAvailableClasses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getUserName = () => {
    if (user?.name && user?.last_name) {
      const userFullName = `${user.name} ${user.last_name}`;
      const splittedName = userFullName.split(" ");

      if (splittedName?.length === 4) {
        return `${splittedName[0]} ${splittedName[2]}`;
      } else {
        return userFullName;
      }
    }
  };

  const renderAvailable = () => {
    if (user !== null && Array.isArray(available_classes)) {
      if (user.has_unlimited) {
        return "Ilimitadas";
      }
      return available_classes.length;
    }
  };

  return (
    <div className="container p-0">
      <div className="row">
        <div className="col-6 px-0 col-md-8">
          <div className="row align-items-center">
            <img
              src={getUserSrc(user)}
              alt="user"
              style={{
                width: "80px",
                height: "80px",
                objectFit: "cover",
                borderRadius: "50%",
              }}
              className="bg-gray p-0 cursor-pointer me-3 hide-mobile"
            />

            <div className="hide-mobile-flex flex-column w-max-content ">
              <h2
                style={{ lineHeight: "40px" }}
                className="text-dark fs-1 w-max-content mb-0 h-max-content"
              >
                {getUserName()}
              </h2>
              <p
                style={{ lineHeight: "24px" }}
                className="fs-4 title-font text-light mb-0"
              >
                {user?.email}
              </p>
            </div>
          </div>
        </div>

        <div className="col-6 col-md-4 pe-0">
          <div className="row justify-content-end align-items-center h-100">
            <h2 className="text-primary fw-normal w-max-content mb-0 px-0 profile__current-package">
              Clases: {renderAvailable()}
            </h2>
          </div>
        </div>
      </div>

      <div className="row align-items-center mt-3">
        <img
          src={getUserSrc(user)}
          alt="user profile image"
          style={{
            width: "80px",
            height: "80px",
            objectFit: "cover",
            borderRadius: "50%",
          }}
          className="bg-gray p-0 cursor-pointer  show-mobile mobile__user-image"
        />

        <div className="show-mobile-flex flex-column w-max-content ">
          <h2
            style={{ lineHeight: "40px" }}
            className="text-dark fs-1 w-max-content mb-0 h-max-content"
          >
            {getUserName()}
          </h2>
          <p
            style={{ lineHeight: "24px" }}
            className="fs-4 title-font text-light mb-0"
          >
            {user?.email}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProfileData;
