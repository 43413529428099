import React from "react";
import Banner from "../global/Banner";
import "../../css/landing-banner.css";

const LandingBanner = () => {
  return (
    <div className="landing-banner container-fluid mb-2 px-0 bg-light" >
      <Banner/>
    </div>
  );
};
export default LandingBanner;
