import React, { useContext, useEffect } from "react";
import PurchasesTable from "../purchases/PurchasesTable";
import CancelForm from "../purchases/CancelForm";
import { PurchasesContext } from "../../context/PurchasesContext";
import { ModalContext } from "../../context/ModalContext";

const PurchasesHistory = () => {
  const { purchases, getPurchases, cancelSubscription } =
    useContext(PurchasesContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getPurchases()
  }, []);


  const confirmCancel = (purchase) => {
    modalComponent(
      "Precaución",
      <CancelForm
        purchase={purchase}
        handleCancel={clearModal}
        cancelSubscription={cancelSubscription}
      />
    );
  };

  return (
    <div className="row p-4">
      <PurchasesTable purchases={purchases} confirmCancel={confirmCancel}/>
    </div>
  );
};

export default PurchasesHistory;
