import React, { useContext, useEffect } from "react";
import ScheduleDay from "./ScheduleDay";
import ScheduleSelectDay from "./ScheduleSelectDay";
import { SingleClassContext } from "../../context/SingleClassContext";

const MobileSchedule = ({  }) => {
  const { getSchedule, getCurrentDateClasses, currentDate, days } = useContext(SingleClassContext);

  useEffect(() => {
    const start_date = currentDate.clone().utc(true).format();
    const end_date = currentDate.clone().utc(true).add(1, 'week').format();

    getSchedule({ start_date, end_date });

  }, [currentDate]);


  const currentDateClasses = () => {
    if(Array.isArray(days)){
      const formattedDate = currentDate.format("YYYY-MM-DD");
      const classes = getCurrentDateClasses(days, formattedDate);
      return classes;
    }
  }


  return (
    <div className="container-fluid px-0" style={{ minHeight: '430px'}}>

      <div className="row align-items-center">
        <div className="col-12 col-lg-4 mb-5 px-0">
          <ScheduleSelectDay/>
        </div>
      </div>

      <div className="row px-3">
        <ScheduleDay single_classes={currentDateClasses()}/>
      </div>

    </div>
  );
};

export default MobileSchedule;
