import React, { useContext, useEffect } from "react";
import HomeCoachCard from "../coaches/HomeCoachCard";
import { InstructorsContext } from "../../context/InstructorsContext";
import EmblaCarousel from "../global/EmblaCarousel";
import "../../css/landing-coaches.css";

const LandingCoaches = () => {
  const { instructors, getHomeInstructors } = useContext(InstructorsContext);

  useEffect(() => {
    getHomeInstructors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCoaches = () => {
    const slideComponents = [];
    if (Array.isArray(instructors)) {
      instructors.forEach((coach) => {
        slideComponents.push(() => {
          return <HomeCoachCard key={coach.instructor_id} coach={coach} />;
        });
      });

      if (instructors && instructors !== null) {
        return (
          <EmblaCarousel
            slides={slideComponents}
            options={{ loop: true }}
            slideClassName=".coaches__embla__slide__card"
          />
        );
      }
    }
  };

  return (
    <div className="container-fluid py-5 bg-accent px-0">
      <div className="coaches__header row w-100 mx-auto mb-5 justify-content-center px-3">
        <p
          className=" px-3 title-font text-center text-dark"
          style={{ fontSize: "40px", fontWeight: 600, width: "max-content" }}
        >
          MEET YOUR COACHES
        </p>
      </div>

      {renderCoaches()}
    </div>
  );
};

export default LandingCoaches;
