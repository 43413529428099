import React from "react";
import { Link } from "@reach/router";
import "../../css/footer.css";

const Footer = () => {
  return (
    <div className="footer bg-dark py-2">
      <div className="container px-md-0 text-dark">
        <div className="row">
          <div className="col-12 text-center mb-3">
            <div className="footer__content row flex-column justify-content-center align-items-center">
              <div className="content__brand col-12 col-md-6">
                <h4 className="w-max-content mt-4 mx-auto text-accent description-font fs-6 fw-normal">
                  © BUNNATION 2024. All Rights Reserved.
                </h4>
              </div>

              <div className="col-12 col-md-6 content__info">
                <div className="info__container d-flex justify-content-end">
                  <Link
                    to="/terminos-y-condiciones"
                    className="text-accent hover-primary d-block mb-2 no-decoration w-max-content mx-auto"
                  >
                    Terms of Use
                  </Link>
                  <Link
                    to="/privacidad"
                    className="text-accent hover-primary d-block mb-2 no-decoration w-max-content ms-md-3 mx-auto"
                  >
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
