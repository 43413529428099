import React, { useContext, useEffect } from "react";
import LoginForm from "../components/auth/LoginForm";
import { AppConfigContext } from "../context/AppConfigContext";
import { navigate } from "@reach/router";
import { AuthContext } from "../context/AuthContext";

const Login = () => {
  const { user } = useContext(AuthContext)
  const { bg_login } = useContext(AppConfigContext);

  useEffect(() => {
    if(user !== null) {
      navigate("/mybunnation");
    }
  }, [user]);

  const getStyle = () => {
    let style = {};
    if (bg_login && bg_login !== null) {
      style.backgroundImage = `url("${bg_login}")`;
    }
    return style;
  };

  return (
    <div className="container-fluid bg-light px-0">
      <div className="row">
        <div className="col-12 col-md-6 bg-black vh-100 px-0 hide-mobile bg-dark">
          <div className="bg-vertical" style={getStyle()}></div>
        </div>

        <div className="col-12 col-md-6 vh-100 bg-accent">
          <div className="row align-items-center vh-100">
            <div className="container-fluid">
              <h1 className="text-center my-4 text-gray">Entrar Ahora</h1>
              <LoginForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
