/**
 * Spinners
 */
export const HIDE_SPINNER = "HIDE_SPINNER";
export const SHOW_SPINNER = "SHOW_SPINNER";
/**
 * Updates
 */
export const SET_UPDATED = "SET_UPDATED";
/**
 * Home
 */
export const ONLINE_HOME_RECIBIDOS = "ONLINE_HOME_RECIBIDOS";
export const ESPECIALES_HOME_RECIBIDOS = "ESPECIALES_HOME_RECIBIDOS";
export const PRESENCIALES_HOME_RECIBIDOS = "PRESENCIALES_HOME_RECIBIDOS";
export const SET_CURRENT_TAB = "SET_CURRENT_TAB";
/**
 * Checkout
 */
export const SET_PAQUETE = "SET_PAQUETE";
/**
 * Metodos Pago
 */
export const METODOS_RECIBIDOS = "METODOS_RECIBIDOS";
/**
 * Class Category
 */
export const CLASS_CATEGORIES_RECIBIDAS = "CLASS_CATEGORIES_RECIBIDAS";
export const CLASS_CATEGORY_RECIBIDA = "CLASS_CATEGORY_RECIBIDA";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const SET_PROPIEDAD_CATEGORY = "SET_PROPIEDAD_CATEGORY";
/**
 * Video Type
 */
export const VIDEO_TYPE_RECIBIDOS = "VIDEO_TYPE_RECIBIDOS";
/**
 * Customers
 */
export const CUSTOMERS_RECIBIDOS = "CUSTOMERS_RECIBIDOS";
export const LEGACY_CUSTOMER = "LEGACY_CUSTOMER";
export const SET_CUSTOMER = "SET_CUSTOMER";
/**
 * Extras
 */
export const TAGS_RECIBIDOS = "TAGS_RECIBIDOS";
export const PROGRAMS_RECIBIDOS = "PROGRAMS_RECIBIDOS";
export const INVOICES_RECEIVED = "INVOICES_RECEIVED";
export const SINGLE_PROGRAM_RECIBIDO = "SINGLE_PROGRAM_RECIBIDO";
