import React, { useContext } from "react";
import ScheduleClass from "./ScheduleClass";
import moment from "moment";
import "./schedule.css";
import { SingleClassContext } from "../../context/SingleClassContext";

const ScheduleDay = ({
  single_classes,
  titleEnabled,
  isActive = true,
  date,
}) => {
  const { currentDate } = useContext(SingleClassContext);

  const getDayClassCategory = () => {
    if(Array.isArray(single_classes)) {
      if(single_classes.length > 0) {
        let current_single_classes = single_classes.sort((a, b) =>
          a.class_date > b.class_date ? 1 : -1
        );
        const firstClass = current_single_classes[0];
        if(firstClass && firstClass !== null) {
          return firstClass.class_type?.name;
        }
      }
    }
  }

  const renderSingleClasses = () => {
    if (Array.isArray(single_classes)) {
      let current_single_classes = [...single_classes];

      if (current_single_classes.length === 0 && isActive) {
        return <p className="text-white small">No hay clases programadas para este día.</p>;
      }

      current_single_classes = current_single_classes.sort((a, b) =>
        a.class_date > b.class_date ? 1 : -1
      );

      return current_single_classes.map((single_class) => (
        <div key={single_class.single_class_id} className="mb-3">
          <ScheduleClass single_class={single_class} isActive={isActive} />
        </div>
      ));
    }
  };

  const renderDay = () => {
    if (titleEnabled && currentDate) {
      const dateFormat = "dddd DD / MM";
      const day_string = moment(date).format(dateFormat);

      return (
        <div className="w-max-content h-max-content mb-4 ">
          <span
            className={`day__description mb-0 title-font fw-normal d-block 
            ${isActive ? "text-primary schedule__current-day__date" : "text-light"}`}
          >
            {day_string}
          </span>
          <span
            className={`day__class-description mb-0 title-font text-primary fw-normal
            ${isActive ? "text-primary schedule__current-day__date" : "text-light"}`}
          >
            {getDayClassCategory()}
          </span>
        </div>
      );
    }
  };

  return (
    <div
      className={`px-0 pb-5 text-center d-flex flex-column embla__slide__number
      align-items-center justify-content-center ${isActive ? 'schedule__current-day' : ''}`}
    >
      {renderDay()}
      {renderSingleClasses()}
    </div>
  );
};

export default ScheduleDay;
