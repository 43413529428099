import React, { useContext } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";
import { getValue } from "../../utils";

const WhatsApp = () => {
  const appconfig = useContext(AppConfigContext);

  if(getValue(appconfig, "whatsapp_widget", 'boolean')) {
    return (
      <a
        target="_blank"
        rel="noreferrer"
        href={`https://wa.me/521${getValue(appconfig, "whatsapp", 'string')}`}
        className="btn btn-success btn-whatsapp"
      >
        <i className="fab fa-whatsapp"></i>{" "}
      </a>
    );
  } else {
    return null;
  }

};
export default WhatsApp;
