import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import ImageInput from "../common/ImageInput";
import { getValue } from "../../utils";
import BirthdateInput from "../common/BirthdateInput";

const EditProfile = () => {
  const { user, updateUsuario, setPropiedadUser } =
    useContext(AuthContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateUsuario(user);
  };

  const getSrc = () => {
    const { file } = user;
    if (file && file !== null && file?.src) {
      return file.src;
    } else if (file && file !== null) {
      return `${file.name}.${file.type}`;
    }
  };

  return (
    <div className="row py-3">
      <form onSubmit={handleSubmit}>
      
        <div className="row mt-3">
          <div className="col-12 col-md-6 px-0">
            <ImageInput
              value={getSrc()}
              modifier={(picture) => setPropiedadUser("picture", picture)}
            />
          </div>

          <div className="col-12 col-md-6">
            <div className="row">

              <div className="col-12">
                <label className="bold">Nombre:</label>
                <input
                  type="text"
                  className="form-control mb-3"
                  value={getValue(user, "name")}
                  onChange={(e) => setPropiedadUser("name", e.target.value)}
                />
              </div>

              <div className="col-12">
                <label className="bold">Apellidos:</label>
                <input
                  type="text"
                  className="form-control mb-3"
                  value={getValue(user, "last_name")}
                  onChange={(e) => setPropiedadUser("last_name", e.target.value)}
                />
              </div>

              <div className="col-6 ">
                <label className="bold">Telefono Celular</label>
                <input
                  type="tel"
                  className="form-control mb-3"
                  value={getValue(user, "phone")}
                  onChange={(e) => setPropiedadUser("phone", e.target.value)}
                />
              </div>

              <div className="col-6">
                <label className="bold">Instagram:</label>
                <input
                  type="text"
                  className="form-control mb-3"
                  value={getValue(user, "instagram")}
                  onChange={(e) => setPropiedadUser("instagram", e.target.value)}
                />
              </div>

              <div className="col-12">
                <label className="bold">Fecha de Nacimiento:</label>
                <BirthdateInput
                  value={getValue(user, "birthdate")}
                  modifier={(value) => setPropiedadUser("birthdate", value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col col-md-6">
            <button 
              type="submit" 
              className="btn btn-dark rounded-0 px-4 fw-normal fs-5"
              style={{ width: '200px'}}
            >
              Guardar
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditProfile;