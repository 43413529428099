import React from "react";
import PurchaseRow from "./PurchaseRow";

const PurchasesTable = ({ purchases, confirmCancel }) => {
  const renderPurchases = () => {
    if (Array.isArray(purchases)) {
      if (purchases.length === 0) {
        return <p>No has hecho ninguna compra.</p>;
      }
      return (
        <div className="card border-0 p-0 no-scale shadow">
          <div className="table-responsive">
            <table className="table table-hover br-15">
              <thead className="bg-dark text-accent border bold mx-0 py-2">
                <tr>
                  <td className="bold">Folio</td>
                  <td className="bold">Paquete</td>
                  <td className="bold">Total</td>
                  <td className="bold">Fecha</td>
                  <td className="bold">Estado</td>
                  <td className="bold">Sig. Cargo</td>
                  <td className="bold">Acciones</td>
                </tr>
              </thead>
              <tbody>
                {purchases.map((purchase) => (
                  <PurchaseRow
                    key={purchase.purchase_id}
                    purchase={purchase}
                    cancelSubscription={confirmCancel}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  };
  return <div className="p-0">{renderPurchases()}</div>;
};

export default PurchasesTable;
