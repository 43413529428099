import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { HomeContext } from "../../context/HomeContext";

const ProfileDesktop = ({ sectionButtons, currentSection, setCurrentSection }) => {
  const { signOut } = useContext(AuthContext);
  const { setCurrentTab } = useContext(HomeContext);


  const renderSectionButtons = () => {
    return sectionButtons.map((current) => {
      const isActive = currentSection.value === current.value;
      return (
        <button
          key={current.value}
          type="button"
          className={`fs-5 btn text-accent text-start position-relative ${
            isActive ? "bg-primary" : "bg-dark"
          }`}
          onClick={() => setCurrentSection(current)}
          style={{ width: "180px" }}
        >
          {current.label}
          <i className="fas fa-chevron-right position-absolute end-0 me-3 mt-1 h-max-content" />
        </button>
      );
    });
  };

  return (
    <div className="row mt-5 hide-mobile flex-nowrap">
      <div
        className="container px-0 m-0 bg-dark py-3 "
        style={{ width: "200px" }}
      >
        <div className="row flex-column w-max-content">
          {renderSectionButtons()}
          <button
            type="button"
            className={`fs-5 btn text-accent text-start position-relative bg-dark`}
            onClick={() => {
              signOut();
              setCurrentTab({ label: 'Login', to: '/login' });
            }}
            style={{ width: "180px" }}
          >
            Log Out
            <i className="fas fa-chevron-right position-absolute end-0 me-3 mt-1 h-max-content" />
          </button>
        </div>
      </div>

      <div
        className="container border-top border-5 border-dark px-0 position-relative"
        style={{ flex: 1, minHeight: "500px" }}
      >
        {currentSection.component}
      </div>
      
    </div>
  );
};

export default ProfileDesktop;
