import React, { useContext, useEffect } from "react";
import Schedule from "../schedule/Schedule";
import MobileSchedule from "../schedule/MobileSchedule";
import "../../css/landing-schedule.css";
import useWindowWidth from "../../hooks/useWindowWidth";
import { AvailableClassesContext } from "../../context/AvailableClassesContext";
import { AuthContext } from "../../context/AuthContext";

const LandingSchedule = ({ isHome }) => {
  const { biggerThanThreshold } = useWindowWidth(1024);
  const { user } = useContext(AuthContext);
  const { available_classes, getAvailableClasses } = useContext(
    AvailableClassesContext
  );

  useEffect(() => {
    if (user !== null) getAvailableClasses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const renderSchedule = () => {
    if (biggerThanThreshold) {
      return <Schedule />;
    } else {
      return <MobileSchedule />;
    }
  };

  const renderAvailable = () => {
    if (user !== null && Array.isArray(available_classes)) {
      if (user.has_unlimited) {
        return "Ilimitadas";
      }
      return available_classes.length;
    }
  };

  return (
    <div
      className={`landing__schedule container-fluid text-center pt-4 ${
        isHome ? "mt-5" : ""
      } bg-dark`}
      id="calendario"
    >
      <div className="row mb-5 align-items-center">
        <div className="col-md-4 hide-mobile" />
        <div className="col-12 col-md-4">
          <h2
            className="text-accent fw-normal mb-0"
            style={{ fontSize: "40px" }}
          >
            CALENDAR
          </h2>
        </div>
        {user !== null && Array.isArray(available_classes) && (
          <div className="col-12 col-md-4 text-white">
            Clases: {renderAvailable()}
          </div>
        )}
      </div>
      {renderSchedule()}
    </div>
  );
};

export default LandingSchedule;
