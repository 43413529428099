import React, { useContext, useEffect } from "react";
import { ClassTypeContext } from "../../context/ClassTypesContext";
import ClassTypesCollage from "../classTypes/ClassTypesCollage";
import HomeClassTypeCard from "../classTypes/HomeClassTypeCard";
import useWindowWidth from "../../hooks/useWindowWidth";
import "../../css/landing-class-types.css";

const LandingClassTypes = () => {
  const { class_types, getClassTypes } = useContext(ClassTypeContext);

  const { biggerThanThreshold } = useWindowWidth(900);

  useEffect(() => {
    getClassTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderClassTypes = () => {
    if (Array.isArray(class_types)) {
      const class_types_render = class_types
        .filter(({ show_home }) => show_home)
        .sort((a, b) => (a.order < b.order ? -1 : 1));
      if (class_types_render.length > 0) {
        if (biggerThanThreshold) {
          return <ClassTypesCollage class_types={class_types_render} />;
        }
        return (
          <div className="container-fluid p-0 mt-5 bg-white">
            {class_types_render.map((class_type) => (
              <HomeClassTypeCard
                key={class_type.class_type_id}
                class_type={class_type}
              />
            ))}
          </div>
        );
      }
    }
  };

  return (
    <div id="class_types" className="container-fluid px-0">
      {renderClassTypes()}
    </div>
  );
};

export default LandingClassTypes;
