import { useEffect, useState } from "react";

const useWindowWidth = (widthThreshold) => {
  const [smallerThanThreshold, setSmallerThanThreshold] = useState(false);
  const [biggerThanThreshold, setBiggerThanThreshold] = useState(false);

  useEffect(() => {
    handleWindowSize();
    window.addEventListener("resize", handleWindowSize);

    return () => {
      window.removeEventListener("resize", handleWindowSize);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleWindowSize = () => {
    if (window.screen.width <= widthThreshold) {
      setSmallerThanThreshold(true);
    } else {
      setSmallerThanThreshold(false);
    }

    if (window.screen.width >= widthThreshold) {
      setBiggerThanThreshold(true);
    } else {
      setBiggerThanThreshold(false);
    }
  };


  return {
    biggerThanThreshold,
    smallerThanThreshold
  };
}

export default useWindowWidth;