import { Link } from "@reach/router";
import React from "react";

const ClassTypeContent = ({ class_type }) => {
  return (
    <div className="row bg-dark class-type__content">
      <p
        className="content__title mb-2 px-3 title-font text-primary fs-2 text-start mt-4"
        style={{ fontWeight: 400 }}
      >
        {class_type.name}
      </p>

      {/*<p
        className="content__subtitle mb-2 px-3 title-font text-accent text-start"
        style={{ fontWeight: 400, fontSize: "30px" }}
      >
        Subtitulo
      </p>*/}

      <p
        className="content__description mb-5 px-3 mt-4 text-accent text-start"
      >
        {class_type.description}
      </p>

      <Link
        to="/paquetes"
        className="content__btn title-font text-primary fs-2 d-flex align-items-center justify-content-center mb-5"
      >
        Join Us Book a Class
        <i className="fas fa-chevron-right text-primary ms-3" />
      </Link>
    </div>
  );
};

export default ClassTypeContent;
