import React, { useContext } from "react";
import { ClassReservationsContext } from "../../context/ClassReservationsContext";
import { getStatusReservacionString } from "../../utils/reservations";
import { AppConfigContext } from "../../context/AppConfigContext";
import ClassReservationRating from "./ClassReservationRating";
import { ModalContext } from "../../context/ModalContext";
import { getUserSrc, grayImg } from "../../utils";
import CancelClass from "../clases/CancelClass";
import moment from "moment";
import "../../css/reservations.css";

const dateFormat = "dddd DD / MM";

const textColors = {
  lightCard: {
    classDate: {
      cancelled: "text-light",
      normal: "text-dark",
    },
    classTime: {
      cancelled: "text-light",
      normal: "text-dark",
    },
    coach: {
      cancelled: "text-light",
      normal: "text-dark",
    },
  },
  darkCard: {
    classDate: "text-primary",
    classTime: "text-accent",
    coach: "text-accent",
  },
};

const ReservacionRow = ({ reservacion, lightCard }) => {
  const { modalComponent } = useContext(ModalContext);
  const { reservations } = useContext(ClassReservationsContext);
  const { reservation_rating_enabled } = useContext(AppConfigContext);

  const { cancelReservacion } = useContext(ClassReservationsContext);

  const isCanceled = reservacion?.deletedAt !== null;

  const confirmCancel = (reservation) => {
    modalComponent(
      "Cancelar Clase",
      <CancelClass
        reservations={reservations}
        singleClass={reservation.single_class}
        cancelReservacion={cancelReservacion}
      />
    );
  };

  const handleRating = () => {
    modalComponent(
      "Calificar Clase",
      <ClassReservationRating
        class_reservation_id={reservacion.class_reservation_id}
      />
    );
  };

  const renderRating = () => {
    if (
      getStatusReservacionString(reservacion) === "Exitosa" &&
      parseInt(reservation_rating_enabled)
    ) {
      if (reservacion.class_stars === null) {
        return (
          <button
            onClick={handleRating}
            className="btn btn-sm btn-outline-primary"
          >
            <i className="fa fa-star me-1"></i> Calificar
          </button>
        );
      }
      return (
        <span>
          {reservacion.class_stars} <i className="fa fa-star text-warning"></i>
        </span>
      );
    }
  };

  const getClassTime = () => {
    const classTime = moment(reservacion?.single_class?.class_date).utc();
    const startTime = classTime.clone().format("HH:mm");
    const endTime = classTime.clone().add(50, "minutes").format("HH:mm");

    return `${startTime} - ${endTime}`;
  };

  const getCoach = () => {
    if (reservacion?.single_class?.class_instructors?.length > 0) {
      const currentCoach =
        reservacion?.single_class?.class_instructors[0].instructor;
      return currentCoach;
    }
  };

  const getCoachSrc = () => {
    if (getCoach()) {
      const currentCoach = getCoach();
      return getUserSrc(currentCoach);
    } else {
      return grayImg;
    }
  };

  const getCoachNameClassTypeText = () => {
    const currentCoach = getCoach();
    const text = `${currentCoach?.nick_name} - ${reservacion?.single_class?.class_type?.name}`;

    return text;
  };

  const renderStatus = () => {
    if (reservacion?.deletedAt !== null) {
      return <p className="title-font text-gray mb-0">Cancelado</p>;
    }

    if (reservacion?.attend) {
      return <p className="title-font text-primary mb-0">Exitosa</p>;
    }

    if (
      moment(reservacion.single_class.class_date).utc().isBefore(moment().utc())
    ) {
      return <p className="title-font text-primary mb-0">No asistí</p>;
    }

    return (
      <button
        type="button"
        onClick={() => confirmCancel(reservacion)}
        className="content__cancel-btn btn bg-dark w-max-content text-primary fs-5"
      >
        Cancelar
      </button>
    );
  };

  const getTextColor = (key) => {
    const reservationStatus = isCanceled ? "cancelled" : "normal";

    if (lightCard) {
      return textColors.lightCard[key][reservationStatus];
    } else {
      return textColors.darkCard[key];
    }
  };

  const getCoachImgStyle = () => {
    const style = {
      width: "50px",
      height: "50px",
    };

    if (isCanceled) style.filter = "grayscale(100%)";
    return style;
  };

  return (
    <div
      key={reservacion.class_reservation_id}
      className={`reservation-card card border-0 no-scale px-md-3 rounded-0 border-bottom border-1 border-gray ${
        lightCard ? "bg-accent" : "bg-dark"
      }`}
      style={{ cursor: "default" }}
    >
      <div className="row reservation-card__content align-items-center justify-content-between">
        <div className="d-flex p-0 align-items-center w-max-content">
          <img
            alt="coach"
            src={getCoachSrc(reservacion)}
            style={getCoachImgStyle()}
            className="bg-gray me-2 me-md-4"
          />
          <div className="content__class-data d-flex align-items-center px-0">
            <div className="class-data__date-time d-flex px-0">
              <h2
                style={{ width: "170px" }}
                className={`date-time__date px-0 mb-0 fw-light me-2 me-md-4 ${getTextColor(
                  "classDate"
                )}`}
              >
                {moment(reservacion?.single_class?.class_date).format(
                  dateFormat
                )}
              </h2>
              <p
                style={{ width: "100px" }}
                className={`date-time__time mb-0 px-0 title-font text-accent fw-normal fs-4 me-4 ${getTextColor(
                  "classTime"
                )}`}
              >
                {getClassTime()}
              </p>
            </div>
            <p
              className={`class-data__coach px-0 mb-0 title-font text-accent w-max-content fw-light fs-5 h-max-content ${getTextColor(
                "coach"
              )}`}
            >
              {getCoachNameClassTypeText()}
            </p>
          </div>
        </div>
        <div className="d-flex w-max-content justify-items-end me-3 ms-auto">
          {renderRating()}
        </div>
        <div className="content__status capitalize mb-0 w-max-content fw-light fs-5">
          {renderStatus()}
        </div>
      </div>
    </div>
  );
};

export default ReservacionRow;
