import React, { useState } from "react";
import "../css/mi-informacion.css";
import ClassReservations from "./ClassReservations";
import ProfileData from "../components/global/ProfileData";
import EditProfile from "../components/profile/EditProfile";
import ProfileMobile from "../components/profile/ProfileMobile";
import ProfileDesktop from "../components/profile/ProfileDesktop";
import PurchasesHistory from "../components/profile/PurchasesHistory";
import Waitlists from "./Waitlists";

const sectionButtons = [
  {
    label: "Siguientes Clases",
    value: "next-classes",
    component: <ClassReservations filter="next" />,
  },
  {
    label: "Clases Pasadas",
    value: "past-classes",
    component: <ClassReservations filter="past" />,
  },
  {
    label: "Lista de Espera",
    value: "waitlist",
    component: <Waitlists />
  },
  {
    label: "Historial de Compras",
    value: "purchases-history",
    component: <PurchasesHistory />,
  },
  {
    label: "Editar Perfil",
    value: "edit-profile",
    component: <EditProfile />,
  },
];

const MiInformacion = () => {
  const [currentSection, setCurrentSection] = useState(sectionButtons[0]);

  return (
    <div className="container px-0 py-4 h-100">
      <div className="row px-3">
        <ProfileData />
      </div>
      <ProfileDesktop
        sectionButtons={sectionButtons}
        currentSection={currentSection}
        setCurrentSection={setCurrentSection}
      />
      <ProfileMobile
        sectionButtons={sectionButtons}
        currentSection={currentSection}
        setCurrentSection={setCurrentSection}
      />
    </div>
  );
};

export default MiInformacion;
