import React, { useContext, useEffect } from "react";
import { ClassReservationsContext } from "../context/ClassReservationsContext";
import { AvailableClassesContext } from "../context/AvailableClassesContext";
import ReservacionRow from "../components/reservaciones/ReservacionRow";
import CancelClass from "../components/clases/CancelClass";
import { ModalContext } from "../context/ModalContext";
import { Link } from "@reach/router";
import moment from "moment";

const ClassReservations = ({ filter }) => {
  const { getAvailableClasses } = useContext(AvailableClassesContext);
  const { reservations, getMyReservations, cancelReservacion } = useContext(
    ClassReservationsContext
  );
  const { modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getMyReservations();
    getAvailableClasses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmCancel = (reservation) => {
    modalComponent(
      "Cancelar Clase",
      <CancelClass
        single_class={reservation}
        cancelReservacion={cancelReservacion}
      />
    );
  };

  const renderReservaciones = () => {
    if (Array.isArray(reservations)) {
      let reservationsRender = [...reservations];
      if (filter === "past") {
        reservationsRender = reservationsRender.filter((reservation) =>
          moment(reservation.single_class.class_date)
            .utc()
            .isBefore(moment().utc(true))
        );
      } else if (filter === "next") {
        reservationsRender = reservationsRender.filter((reservation) =>
          moment(reservation.single_class.class_date)
            .utc()
            .isAfter(moment().utc(true))
        );
      }
      if (reservationsRender.length === 0) {
        return (
          <div className="container-fluid p-3">
            <p>No tienes clases reservadas {filter === "next" ? "próximas": "pasadas"}.</p>
            <Link to="/mybunnation/presencial" className="btn btn-primary">
              Ir a Reservar
            </Link>
          </div>
        );
      }
      return reservationsRender.map((reservation) => (
        <ReservacionRow
          key={reservation.class_reservation_id}
          lightCard={filter === "past"}
          handleCancel={confirmCancel}
          reservacion={reservation}
        />
      ));
    }
    return <div className="spinner-border"></div>;
  };

  return <div className="row">{renderReservaciones()}</div>;
};

export default ClassReservations;
