import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { getUserSrc } from "../../utils";
import { navigate } from "@reach/router";
import { HomeContext } from "../../context/HomeContext";

const UserNavBtn = ({ hideMobile }) => {
  const { user } = useContext(AuthContext);
  const { setCurrentTab } = useContext(HomeContext);

  return (
    <button
      type="button"
      className={`btn p-0 w-max-content ${hideMobile ? 'hide-mobile' : ''}`}
      onClick={() => {
        navigate("/mybunnation/info");
        setCurrentTab({
          label: "",
          to: "",
        });
      }}
    >
      <img
        src={getUserSrc(user)}
        alt="user"
        style={{
          width: "40px",
          height: "40px",
          objectFit: "cover",
          borderRadius: "50%",
        }}
        className="bg-gray p-0 cursor-pointer me-3"
      />
      <p className="d-inline cursor-pointer w-max-content text-accent title-font fs-4 m-0">
        {user?.name}
      </p>
    </button>
  );
};

export default UserNavBtn;
