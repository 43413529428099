import React from "react";
import Banner from "../components/global/Banner";
import LandingSchedule from "../components/landing/LandingSchedule";

const Calendario = () => {

  return (
    <div className="container-fluid pt-3 px-0 bg-dark" >
      <LandingSchedule isHome={false} />
      <Banner overlayLight/>
    </div>
  );
};

export default Calendario;